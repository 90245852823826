import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { initialState, API_CONSTANTS } from "../../utils";
import { myAxios } from "../../utils/myAxios";
import { WebSession } from "../../utils/webSession";
import { logout, setSessionLogout } from "../auth/logoutSlice";
import { getAllNotebookReload } from "../initialRequest/getNotebookSlice";

/**
 * @category Redux
 * @subcategory userActivity
 * @namespace slice:bookUseractivitySlice
 * @description bookUseractivitySlice is a Redux slice for managing user activity related to a book, such as fetching user activity data for a specific book.
 */

/**
 * @memberof slice:bookUseractivitySlice
 * @method bookUseractivityAsync
 * @async
 * @description REST API call to fetch user activity for a specific book.
 * @param {Object} params Parameters for the API call.
 * @param {string} params.bookId The ID of the book whose user activity is being logged.
 * @returns {Promise<string>} Resolves with the status message on success, or rejects with an error message on failure.
 * @throws {Error} Handles API errors, including session expiration or rate limits.
 */

export const bookUseractivityAsync = createAsyncThunk(
  "book/useractivity",
  async ({ bookId }, { rejectWithValue, getState, dispatch }) => {
    try {
      const token = WebSession()?.access_token;
      const response = await myAxios(
        token,
        getState().saveUserUniqueId.userUniqueId
      ).post(`api/book/useractivity`, bookId, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        if (response.data.status.statusCode === 200) {
          return response.data.status.statusMessage;
        } else {
          return rejectWithValue(response.data.status.errorMessage);
        }
      } else {
        return rejectWithValue("error on api");
      }
    } catch (err) {
      if (err?.response?.status === 429) {
        dispatch(logout());
      } else if (err?.response?.status === 401) {
        dispatch(setSessionLogout(true));
        return rejectWithValue(API_CONSTANTS.SESSION_EXPIRED);
      } else {
        return rejectWithValue(err?.message);
      }
    }
  }
);

/**
 * @memberof slice:bookUseractivitySlice
 * @name initialState
 * @type {import('../../utils').initialStateParam} initialState
 * @description Initial state of the slice, including loading, error, and data flags.
 */

export const bookUseractivitySlice = createSlice({
  name: "bookUseractivity",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(bookUseractivityAsync.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(bookUseractivityAsync.fulfilled, (state, action) => {
      state.hasData = true;
      state.hasError = false;
      state.isLoading = false;
    });

    builder.addCase(bookUseractivityAsync.rejected, (state, action) => {
      state.hasData = false;
      state.error = action.payload;
      state.hasError = true;
      state.isLoading = false;
    });
  },
  reducers: {
    /**
     * @memberof slice:bookUseractivitySlice
     * @method resetBookUseractivity
     * @description Resets the state of the bookUseractivitySlice to its initial values.
     */
    resetBookUseractivity: (state) => {
      state.isLoading = false;
      state.hasData = false;
      state.hasError = false;
      state.data = null;
      state.error = null;
    },
  },
});

export const { resetBookUseractivity } = bookUseractivitySlice.actions;

export default bookUseractivitySlice.reducer;

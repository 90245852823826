import storage from "redux-persist/lib/storage";
import storageSession from "redux-persist/lib/storage/session";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";

//reducers
import logout from "./slice/auth/logoutSlice";
import getAllFavoriteBooks from "./slice/favoriteSlice/getAllFavoriteBooks";
import updateFavoriteBook from "./slice/favoriteSlice/updateFavoriteBook";
import getAllBookList from "./slice/bookShelfSlice/getAllBookList";
import getAllBooksByShelveId from "./slice/bookShelfSlice/getAllBooksByShelveId";
import getAllHighlights from "./slice/highlight/getAllHighlights";
import getAllBookmarks from "./slice/bookmark/getAllBookmarks";
import getAllNotes from "./slice/notes/getAllNotes";
import getHighlightById from "./slice/highlight/getHighlightById";
import getBookmarkById from "./slice/bookmark/getBookmarkById";
import getNotesById from "./slice/notes/getNotesById";
import deleteNotesById from "./slice/notes/deleteNotesById";
import deleteHighlightsById from "./slice/highlight/deleteHighlightsById";
import deleteBookmarksById from "./slice/bookmark/deleteBookmarksById";
import searchHistory from "./slice/searchHistory/searchHistory";
import removeSearchHistory from "./slice/searchHistory/removeSearchHistory";
import addSearchHistory from "./slice/searchHistory/addSearchHistory";
import groupedSearch from "./slice/bookSearch/groupedSearch";
import snackbar from "./slice/snackbarSlice";
import bookSorting from "./slice/bookSortingSlice/bookSortingSlice";
import bookReader from "./slice/bookReaderSlice";
import bookData from "./slice/bookReaderSlice/bookDataSlice";
import getBookById from "./slice/bookReaderSlice/getBookByIdSlice";
import updateNotesById from "./slice/notes/updateNotesById";
import bookToolsPopup from "./slice/bookReaderSlice/bookToolsPopupSlice";
import createNotes from "./slice/notes/createNotesSlice";
import addHighlights from "./slice/highlight/addHighlights";
import addBookmark from "./slice/bookmark/addBookmarkSlice";
import deleteMultiHighlights from "./slice/highlight/deleteMultiHighlightsSlice";
import addMultiHighlights from "./slice/highlight/addMultiHighlightsSlice";
import feedbackModal from "./slice/feedbackSlice/feedbackModalSlice";
import feedback from "./slice/feedbackSlice";
import companyInfo from "./slice/auth/companyInfoSlice";
import reloader from "./slice/reloaderSlice";
import menuPopup from "./slice/menuPopupSlice";
import applicationVersion from "./slice/auth/applicationVersionSlice";
import screenPath from "./slice/screenPathSlice";
import getDeprecatedBooks from "./slice/deprecatedBooks/getDeprecatedBooksSlice";
import getPersonalShelf from "./slice/personalBookShelfSlice";
import epubBookData from "./slice/bookReaderSlice/epubBookData";
import bookLocations from "./slice/bookReaderSlice/bookLocationsSlice";
import bookUseractivity from "./slice/bookReaderSlice/bookUserActivity";
import getBookShelves from "./slice/initialRequest/getBookShelvesSlice";
import getNotebook from "./slice/initialRequest/getNotebookSlice";
import getNotebookById from "./slice/initialRequest/getNotebookByIdSlice";
import bookResume from "./slice/bookmark/bookResumeSlice";
import autoComplete from "./slice/searchHistory/autoCompleteSlice";
import mobilePromotion from "./slice/mobilePromotionSlice";
import browserSupport from "./slice/browserSupportSlice";
import listedSearch from "./slice/bookSearch/listedSearch";
import gtmEvent from "./slice/gtmEventSlice";
import relatedSearch from "./slice/bookSearch/relatedSearchSlice";
import relatedSearchDetails from "./slice/bookSearch/relatedSearchDetailsSlice";
import searchFilter from "./slice/bookSearch/searchFilterSlice";
import getBookMetaById from "./slice/books/getBookMetaByIdSlice";

//userUniqueId
import deleteUserUniqueId from "./slice/userUniqueIdSlice/deleteUserUniqueIdSlice";
import updateUserUniqueId from "./slice/userUniqueIdSlice/updateUserUniqueIdSlice";
import getAllUserUniqueId from "./slice/userUniqueIdSlice/getAllUserUniqueIdSlice";
import getUserUniqueId from "./slice/userUniqueIdSlice/getUserUniqueIdSlice";
import saveUserUniqueId from "./slice/userUniqueIdSlice/saveUserUniqueIdSlice";
import sessionPopup from "./slice/userUniqueIdSlice/sessionPopupSlice";
import getBuyBookLink from "./slice/books/getBuyBookLink";
import dashboardAccordion from "./slice/dashboardAccordionSlice";

/**
 * @category Redux
 * @namespace RootReducer
 * @description This folder contains redux setup for the application
 */

/**
 * @memberof RootReducer
 * @name localStorageConfig
 * @description Storage key,storage type and whitelist for local db storing reducers
 */

const localStorageConfig = {
  key: "root",
  storage,
  whitelist: [
    "applicationVersion",
    "screenPath",
    "saveUserUniqueId",
    "mobilePromotion",
  ],
};

/**
 * @memberof RootReducer
 * @name sessionStorageConfig
 * @method
 * @description Storage key,storage type for session db storing reducers
 */
const sessionStorageConfig = (sessionKey) => ({
  key: sessionKey,
  storage: storageSession,
});

/**
 * @memberof RootReducer
 * @name reducer
 * @description Combining reducers
 */
const reducer = combineReducers({
  logout,
  getAllFavoriteBooks,
  updateFavoriteBook,
  getAllBookList,
  getAllBooksByShelveId,
  getAllNotes,
  getAllBookmarks,
  getAllHighlights,
  deleteBookmarksById,
  deleteHighlightsById,
  deleteNotesById,
  removeSearchHistory,
  addSearchHistory,
  groupedSearch,
  snackbar,
  bookSorting,
  bookReader,
  bookData,
  updateNotesById,
  bookToolsPopup,
  createNotes,
  addHighlights,
  addBookmark,
  deleteMultiHighlights,
  feedback,
  feedbackModal,
  reloader,
  menuPopup,
  applicationVersion,
  screenPath,
  getDeprecatedBooks,
  getPersonalShelf,
  deleteUserUniqueId,
  updateUserUniqueId,
  getAllUserUniqueId,
  getUserUniqueId,
  saveUserUniqueId,
  sessionPopup,
  getBuyBookLink,
  addMultiHighlights,
  epubBookData,
  getBookShelves,
  getNotebook,
  getNotebookById,
  bookResume,
  autoComplete,
  searchHistory,
  mobilePromotion,
  listedSearch,
  gtmEvent,
  relatedSearch,
  relatedSearchDetails,
  getBookMetaById,
  bookUseractivity,
  // companyInfo,

  //session storage
  companyInfo: persistReducer(sessionStorageConfig("companyInfo"), companyInfo),
  getHighlightById: persistReducer(
    sessionStorageConfig("getHighlightById"),
    getHighlightById
  ),
  getBookmarkById: persistReducer(
    sessionStorageConfig("getBookmarkById"),
    getBookmarkById
  ),
  getNotesById: persistReducer(
    sessionStorageConfig("getNotesById"),
    getNotesById
  ),
  getBookById: persistReducer(sessionStorageConfig("getBookById"), getBookById),
  bookLocations: persistReducer(
    sessionStorageConfig("bookLocations"),
    bookLocations
  ),
  browserSupport: persistReducer(
    sessionStorageConfig("browserSupport"),
    browserSupport
  ),
  dashboardAccordion: persistReducer(
    sessionStorageConfig("dashboardAccordion"),
    dashboardAccordion
  ),
  searchFilter: persistReducer(
    sessionStorageConfig("searchFilter"),
    searchFilter
  ),
});

/**
 * @memberof RootReducer
 * @name rootReducer
 * @description Persisting the combined reducers and exporting as rootReducer.
 */
const rootReducer = persistReducer(localStorageConfig, reducer);

export default rootReducer;
